<template>
  <v-container fill fluid>
    <HouseSkeleton v-if="loading" />
    <!-- Owner Order by Placement -->
    <!-- Use UserCardList Code -->
    <v-row style="fill-height" no-gutters align="start">
      <v-col
        v-for="user in users" 
        :key="user.user_id"
        cols="1"
      >
        <OwnerCard :owner="user"/>
      </v-col>
    </v-row>
    <!-- Player on Auction -->
    <!-- <v-row style="fill-height" no-gutters align="start">
      <v-col
        v-for="rfaPlayer in rfaPlayers"
        :key=""
        cols="1"
      >
        <ReadOnlyCard :="rfaPlayers"/>
      </v-col>
    </v-row> -->
  </v-container>
</template>

<script>
import rfaMixin from '../../mixins/rfaMixin.js'
import { mapState, mapActions } from 'vuex';
import OwnerCard from './cards/Owner';
//import ReadOnlyCard from './cards/ReadOnly';
import HouseSkeleton from './skeletons/House';
import _orderBy from 'lodash/orderBy';
import _find from 'lodash/find';
import _parseInt from 'lodash/parseInt';


export default {
  name: 'AuctionHouse',
  mixins: [rfaMixin],
  async mounted () {
    try {
      this.loading = true;
      await this.setOwnerYearRosterStateAsync(this.findPreviousSeasonLeagueId());
      let getLeagueUsersTask = this.getLeagueUsersAsync();
      let getRfaPlayersTask = this.getRfaPlayersAsync();
      
      await getLeagueUsersTask();
      await getRfaPlayersTask();     
    } finally {
      this.loading = false;
    }
  },
  
  components: {
    HouseSkeleton,
    OwnerCard,
    //ReadOnlyCard
  },
  data() {
    return {
      users: [],
      loading: true,
    };
  },
  computed: {
    ...mapState('league', ['draft', 'rosters', 'yearRosters', 'seasons', 'season']),
  },
  methods: {
    ...mapActions('league', ['setDraft', 'setRosters']),
    findPreviousSeasonLeagueId() {
      let prevYear = _parseInt(this.season.year) - 1;
      let result = _find(this.seasons, {'year': prevYear.toString()});
      return result.leagueId;
    },
    async getLeagueUsersAsync () {
      let me = this;
      let setRostersTask = me.setRosters();
      let setDraftTask = me.setDraft();
      let users = me.owners;
      await setRostersTask;
      await setDraftTask;
      let rosters = me.rosters;
      let draftOrder = me.draft.draft_order;

      users.forEach((user) => {
        let rosterIndex = rosters.findIndex((item) => item.owner_id == user.user_id);
        let roster = rosters[rosterIndex];
        user['roster'] = {
          faab: 1000 - roster.settings.waiver_budget_used
        };

        user['draft_order'] = draftOrder[user.user_id];
        user['avatar_size'] = 40;
        user['avatar_thumb'] = 'https://sleepercdn.com/avatars/thumbs/' + user.avatar;
      });

      this.users = _orderBy(users, ['draft_order'], ['desc']);
    },
    // async setupBoard(){
    //   //TODO: Fill with code
    // }
  }
};
</script>

<style></style>
