/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getIntegrationSleeper = /* GraphQL */ `
  query GetIntegrationSleeper($id: ID!) {
    getIntegrationSleeper(id: $id) {
      id
      range_key
      hashtag
      depth_chart_position
      status
      sport
      fantasy_positions
      number
      search_last_name
      injury_start_date
      weight
      position
      practice_participation
      sportradar_id
      team
      last_name
      college
      fantasy_data_id
      injury_status
      player_id
      height
      search_full_name
      age
      stats_id
      birth_country
      espn_id
      search_rank
      first_name
      depth_chart_order
      years_exp
      rotowire_id
      rotoworld_id
      search_first_name
      yahoo_id
      createdAt
      updatedAt
    }
  }
`;
export const listIntegrationSleepers = /* GraphQL */ `
  query ListIntegrationSleepers(
    $filter: ModelIntegrationSleeperFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIntegrationSleepers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        range_key
        hashtag
        depth_chart_position
        status
        sport
        fantasy_positions
        number
        search_last_name
        injury_start_date
        weight
        position
        practice_participation
        sportradar_id
        team
        last_name
        college
        fantasy_data_id
        injury_status
        player_id
        height
        search_full_name
        age
        stats_id
        birth_country
        espn_id
        search_rank
        first_name
        depth_chart_order
        years_exp
        rotowire_id
        rotoworld_id
        search_first_name
        yahoo_id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRequestForChange = /* GraphQL */ `
  query GetRequestForChange($id: ID!) {
    getRequestForChange(id: $id) {
      id
      leagueId
      ownerId
      proposal
      reason
      effectiveDate
      yes {
        id
        email
      }
      no {
        id
        email
      }
      status
      firstName
      lastName
      userId
      userEmail
      createdAt
      updatedAt
    }
  }
`;
export const listRequestForChanges = /* GraphQL */ `
  query ListRequestForChanges(
    $filter: ModelRequestForChangeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRequestForChanges(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        leagueId
        ownerId
        proposal
        reason
        effectiveDate
        yes {
          id
          email
        }
        no {
          id
          email
        }
        status
        firstName
        lastName
        userId
        userEmail
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getWheelOfLast = /* GraphQL */ `
  query GetWheelOfLast($id: ID!) {
    getWheelOfLast(id: $id) {
      id
      leagueId
      text
      description
      user {
        id
        email
      }
      selected
      createdAt
      updatedAt
    }
  }
`;
export const listWheelOfLasts = /* GraphQL */ `
  query ListWheelOfLasts(
    $filter: ModelWheelOfLastFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWheelOfLasts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        leagueId
        text
        description
        user {
          id
          email
        }
        selected
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDues = /* GraphQL */ `
  query GetDues($id: ID!) {
    getDues(id: $id) {
      id
      leagueId
      user {
        id
        email
      }
      amountDue
      amountPaid
      createdAt
      updatedAt
    }
  }
`;
export const listDues = /* GraphQL */ `
  query ListDues(
    $filter: ModelDuesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDues(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        leagueId
        user {
          id
          email
        }
        amountDue
        amountPaid
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listWheelOfLastsByLeague = /* GraphQL */ `
  query ListWheelOfLastsByLeague(
    $leagueId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelWheelOfLastFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWheelOfLastsByLeague(
      leagueId: $leagueId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        leagueId
        text
        description
        user {
          id
          email
        }
        selected
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listDuesByLeague = /* GraphQL */ `
  query ListDuesByLeague(
    $leagueId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelDuesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDuesByLeague(
      leagueId: $leagueId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        leagueId
        user {
          id
          email
        }
        amountDue
        amountPaid
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
