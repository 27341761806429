/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createIntegrationSleeper = /* GraphQL */ `
  mutation CreateIntegrationSleeper(
    $input: CreateIntegrationSleeperInput!
    $condition: ModelIntegrationSleeperConditionInput
  ) {
    createIntegrationSleeper(input: $input, condition: $condition) {
      id
      range_key
      hashtag
      depth_chart_position
      status
      sport
      fantasy_positions
      number
      search_last_name
      injury_start_date
      weight
      position
      practice_participation
      sportradar_id
      team
      last_name
      college
      fantasy_data_id
      injury_status
      player_id
      height
      search_full_name
      age
      stats_id
      birth_country
      espn_id
      search_rank
      first_name
      depth_chart_order
      years_exp
      rotowire_id
      rotoworld_id
      search_first_name
      yahoo_id
      createdAt
      updatedAt
    }
  }
`;
export const updateIntegrationSleeper = /* GraphQL */ `
  mutation UpdateIntegrationSleeper(
    $input: UpdateIntegrationSleeperInput!
    $condition: ModelIntegrationSleeperConditionInput
  ) {
    updateIntegrationSleeper(input: $input, condition: $condition) {
      id
      range_key
      hashtag
      depth_chart_position
      status
      sport
      fantasy_positions
      number
      search_last_name
      injury_start_date
      weight
      position
      practice_participation
      sportradar_id
      team
      last_name
      college
      fantasy_data_id
      injury_status
      player_id
      height
      search_full_name
      age
      stats_id
      birth_country
      espn_id
      search_rank
      first_name
      depth_chart_order
      years_exp
      rotowire_id
      rotoworld_id
      search_first_name
      yahoo_id
      createdAt
      updatedAt
    }
  }
`;
export const deleteIntegrationSleeper = /* GraphQL */ `
  mutation DeleteIntegrationSleeper(
    $input: DeleteIntegrationSleeperInput!
    $condition: ModelIntegrationSleeperConditionInput
  ) {
    deleteIntegrationSleeper(input: $input, condition: $condition) {
      id
      range_key
      hashtag
      depth_chart_position
      status
      sport
      fantasy_positions
      number
      search_last_name
      injury_start_date
      weight
      position
      practice_participation
      sportradar_id
      team
      last_name
      college
      fantasy_data_id
      injury_status
      player_id
      height
      search_full_name
      age
      stats_id
      birth_country
      espn_id
      search_rank
      first_name
      depth_chart_order
      years_exp
      rotowire_id
      rotoworld_id
      search_first_name
      yahoo_id
      createdAt
      updatedAt
    }
  }
`;
export const createRequestForChange = /* GraphQL */ `
  mutation CreateRequestForChange(
    $input: CreateRequestForChangeInput!
    $condition: ModelRequestForChangeConditionInput
  ) {
    createRequestForChange(input: $input, condition: $condition) {
      id
      leagueId
      ownerId
      proposal
      reason
      effectiveDate
      yes {
        id
        email
      }
      no {
        id
        email
      }
      status
      firstName
      lastName
      userId
      userEmail
      createdAt
      updatedAt
    }
  }
`;
export const updateRequestForChange = /* GraphQL */ `
  mutation UpdateRequestForChange(
    $input: UpdateRequestForChangeInput!
    $condition: ModelRequestForChangeConditionInput
  ) {
    updateRequestForChange(input: $input, condition: $condition) {
      id
      leagueId
      ownerId
      proposal
      reason
      effectiveDate
      yes {
        id
        email
      }
      no {
        id
        email
      }
      status
      firstName
      lastName
      userId
      userEmail
      createdAt
      updatedAt
    }
  }
`;
export const deleteRequestForChange = /* GraphQL */ `
  mutation DeleteRequestForChange(
    $input: DeleteRequestForChangeInput!
    $condition: ModelRequestForChangeConditionInput
  ) {
    deleteRequestForChange(input: $input, condition: $condition) {
      id
      leagueId
      ownerId
      proposal
      reason
      effectiveDate
      yes {
        id
        email
      }
      no {
        id
        email
      }
      status
      firstName
      lastName
      userId
      userEmail
      createdAt
      updatedAt
    }
  }
`;
export const createWheelOfLast = /* GraphQL */ `
  mutation CreateWheelOfLast(
    $input: CreateWheelOfLastInput!
    $condition: ModelWheelOfLastConditionInput
  ) {
    createWheelOfLast(input: $input, condition: $condition) {
      id
      leagueId
      text
      description
      user {
        id
        email
      }
      selected
      createdAt
      updatedAt
    }
  }
`;
export const updateWheelOfLast = /* GraphQL */ `
  mutation UpdateWheelOfLast(
    $input: UpdateWheelOfLastInput!
    $condition: ModelWheelOfLastConditionInput
  ) {
    updateWheelOfLast(input: $input, condition: $condition) {
      id
      leagueId
      text
      description
      user {
        id
        email
      }
      selected
      createdAt
      updatedAt
    }
  }
`;
export const deleteWheelOfLast = /* GraphQL */ `
  mutation DeleteWheelOfLast(
    $input: DeleteWheelOfLastInput!
    $condition: ModelWheelOfLastConditionInput
  ) {
    deleteWheelOfLast(input: $input, condition: $condition) {
      id
      leagueId
      text
      description
      user {
        id
        email
      }
      selected
      createdAt
      updatedAt
    }
  }
`;
export const createDues = /* GraphQL */ `
  mutation CreateDues(
    $input: CreateDuesInput!
    $condition: ModelDuesConditionInput
  ) {
    createDues(input: $input, condition: $condition) {
      id
      leagueId
      user {
        id
        email
      }
      amountDue
      amountPaid
      createdAt
      updatedAt
    }
  }
`;
export const updateDues = /* GraphQL */ `
  mutation UpdateDues(
    $input: UpdateDuesInput!
    $condition: ModelDuesConditionInput
  ) {
    updateDues(input: $input, condition: $condition) {
      id
      leagueId
      user {
        id
        email
      }
      amountDue
      amountPaid
      createdAt
      updatedAt
    }
  }
`;
export const deleteDues = /* GraphQL */ `
  mutation DeleteDues(
    $input: DeleteDuesInput!
    $condition: ModelDuesConditionInput
  ) {
    deleteDues(input: $input, condition: $condition) {
      id
      leagueId
      user {
        id
        email
      }
      amountDue
      amountPaid
      createdAt
      updatedAt
    }
  }
`;
